import { useQuery } from '@apollo/react-hooks'
import { AchievementInfos, GetAchievementsResponse } from '@interfaces/Achievements'
import { GET_ACHIEVEMENTS } from '@queries/achievements'
import { useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'

const useAchievements = () => {
  const profile = useSelector(({ userProfile }: StoreProps) => userProfile.info)
  const { loading, data: GetAchievements } = useQuery<GetAchievementsResponse>(GET_ACHIEVEMENTS, {
    variables: {
      studentProfileId: profile.SFId,
    },
  })

  return { loading, Achievements: GetAchievements?.getAchievements?.data ?? ([] as AchievementInfos[]) }
}

export default useAchievements
