import gql from 'graphql-tag'

export const POST_LEVEL_CHANGE = gql`
  mutation PostLevelChange($LevelChangeInput: [LevelChangeInput!]!) {
    postLevelChange(LevelChangeInput: $LevelChangeInput) {
      Success
      StatusCode
    }
  }
`
