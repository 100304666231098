import React from 'react'
import { StyledMaintenanceBanner, SubHeader, Header, Image, Content, ActionButtons, Button } from './style'
import { MaintenanceBannerProps } from './interface'
import ImageMaintenance from '@berlitzplatforms/micro.image.maintenance'
import Spacer from '@berlitz/spacer'

/**
 * a component that displays a configurable maintenance banner
 * @render react
 * @name MaintenanceBanner
 * @example
 * import React from 'react'
 * import MaintenanceBanner from '@berlitzplatforms/micro.ui.maintenance-banner'
 * import { ThemeProvider } from 'styled-components'
 * import theme, { GlobalStyle } from '@berlitzplatforms/micro.theme.portal'
 *
 * export default (
 *   <ThemeProvider theme={theme}>
 *    <GlobalStyle />
 *    <MaintenanceBanner />
 *   </ThemeProvider>
 * )
 */
const MaintenanceBanner: React.FC<MaintenanceBannerProps> = ({
  header,
  subHeader,
  image,
  imageWidth,
  btnText1,
  btnText2,
  onButtonClick1,
  onButtonClick2,
}) => (
  <StyledMaintenanceBanner>
    <Image width={imageWidth}>{image ? image : <ImageMaintenance />}</Image>
    <Content>
      {header && <Header>{header}</Header>}
      <Spacer size="xxxs" />
      {subHeader && <SubHeader>{subHeader}</SubHeader>}
      <Spacer size="xs" />
      <ActionButtons>
        {onButtonClick1 && btnText1 && <Button onClick={() => onButtonClick1()}>{btnText1}</Button>}
        {onButtonClick2 && btnText2 && <Button onClick={() => onButtonClick2()}>{btnText2}</Button>}
      </ActionButtons>
    </Content>
  </StyledMaintenanceBanner>
)

export default MaintenanceBanner
