import styled, { css } from 'styled-components'
import { HiddenProp, TabProp } from './interface'

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;

  .card-item {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Tab = styled.div<TabProp>`
  width: max-content;
  padding: ${({ theme }) => `${theme.space.xxs} ${theme.space.md}`};
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  background-color: ${({ theme }) => theme.colors.brandPrimary};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: ${({ theme }) => theme.space.md};

  ${({ spacer }) =>
    spacer &&
    css`
      visibility: hidden;
    `}
`

export const Right = styled.div`
  padding-right: 26px;
  overflow-y: auto;
  display: flex;
  padding-left: 15px;

  & > div {
    margin-right: 15px;

    &:first-child > div:last-child {
      padding-right: 15px;
      border-right: 2px solid ${({ theme }) => theme.palette.blue30};
    }
  }
`

export const RightNoBorder = styled.div`
  padding-right: ${({ theme }) => theme.layout.xs};
  overflow-y: auto;
  display: flex;
  padding-left: ${({ theme }) => theme.layout.xxs};

  & > div {
    margin-right: ${({ theme }) => theme.layout.xxs};
  }
`

export const Left = styled.div`
  margin: ${({ theme }) => `${theme.space.lg} 0px ${theme.space.lg} 26px`};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: ${({ theme }) => `${theme.space.md} ${theme.space.md} ${theme.space.md} ${theme.space.md}`};
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.56px;
  }
`

export const WatchWrapper = styled.div`
  display: flex;
  align-items: center;

  a {
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-left: ${({ theme }) => theme.space.md};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`

export const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space.lg};
  background-color: ${({ theme }) => theme.palette.blue10};
  border-radius: 3px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 0px;
  }
`

export const Hidden = styled.div<HiddenProp>`
  @media (max-width: ${({ theme, view = 'sm' }) => theme.breakpoints[view]}) {
    display: none;
  }
`

export const Visible = styled.div<HiddenProp>`
  display: none;

  @media (max-width: ${({ theme, view = 'sm' }) => theme.breakpoints[view]}) {
    display: block;
  }
`

export const HeaderContainer = styled.div`
  padding: 15px;

  & > div {
    align-items: center;
  }

  span[data-key='icon-ArrowUp'] {
    display: inline-block;
    color: ${({ theme }) => theme.colors.brandPrimary};
    padding: ${({ theme }) => theme.space.xxs};
    width: 32px;
    text-align: center;
    font-size: 24px;

    &.icon-rotate {
      transform: rotate(180deg);
    }
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BodyWrapper = styled.div`
  p {
    margin-bottom: ${({ theme }) => theme.space.xs};
  }
  margin-bottom: ${({ theme }) => theme.space.xs};
  display: inline-block;
`

export const ProgramsWrapper = styled.div<{ bgOn?: boolean }>`
  & > span {
    display: block;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.xxxs};
    letter-spacing: 0.5px;
    padding-bottom: ${({ theme }) => theme.space.xs};
  }
  & > div {
    display: flex;
    & > div {
      width: 100%;
    }
  }
  ${({ theme, bgOn }) =>
    bgOn &&
    css`
      display: flex;
      align-items: center;
      background: ${theme.palette.blue20};
      border-radius: ${theme.radius.md};
      padding: ${theme.space.sm};
      margin-bottom: ${theme.space.md};
      & > span {
        display: inline-block;
        font-size: ${theme.fontSize.sm};
        color: ${theme.colors.brandPrimary};
        padding-bottom: 0;
        padding-right: ${theme.space.xs};
      }

      & > div {
        min-width: 25%;
      }

      @media (max-width: ${theme.breakpoints.lg}) {
        & > div {
          min-width: 41.667%;
        }
      }

      @media (max-width: ${theme.breakpoints.md}) {
        display: block;
        & > span {
          display: block;
          padding-bottom: ${theme.space.sm};
        }
      }
    `};
`
export const BannerContainer = styled.div`
  margin-top: ${({ theme }) => theme.space.md};
  background-color: ${({ theme }) => theme.palette.white};
  border: 2px solid ${({ theme }) => theme.palette.blue100};
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.17);
  border-radius: ${({ theme }) => theme.radius.lg};

  &.contractExpired {
    padding-bottom: ${({ theme }) => theme.space.md};
    padding-left: ${({ theme }) => theme.space.md};
    padding-right: ${({ theme }) => theme.space.md};
    padding-top: ${({ theme }) => theme.space.xs};
  }

  &.allLevelsCompleted {
    div div {
      h4 {
        padding-top: ${({ theme }) => theme.space.xs};
      }
      div:not(:last-child) {
        padding-left: ${({ theme }) => theme.space.sm};
        padding-right: ${({ theme }) => theme.space.sm};
      }

      div:last-child {
        border-bottom-right-radius: ${({ theme }) => theme.radius.lg};
        border-bottom-left-radius: ${({ theme }) => theme.radius.lg};
      }
    }
  }

  .left-cloud.cloud-bg {
    left: initial;
    right: -13.5em;
  }
`

export const HiddenSpacer = styled.div`
  height: 16px;
  width: 100%;
  transition: 120ms cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: #f4f8ff;
`

export const MaintenanceContainer = styled.div`
  margin-right: ${({ theme }) => theme.space.xl};
  margin-top: ${({ theme }) => theme.space.xl};
  margin-left: ${({ theme }) => theme.space.xl};
  background-color: #f4f8ff;
  padding-left: ${({ theme }) => theme.space.xl};
  padding-top: ${({ theme }) => theme.space.xl};
  padding-bottom: ${({ theme }) => theme.space.lg};
  padding-right: ${({ theme }) => theme.space.xl};
`
