import { useEffect } from 'react'

export type ToggleAction = () => void

const useEnsureNoDisplayedModal = (callback: Function, variables: any[] = [], cancelAction?: boolean) => {
  const watchers: number[] = []

  useEffect(() => {
    const tms = new Date().valueOf()
    // sometimes the timezone modal shows up and the modal gets stacked
    // so we wait for any modal to be dismissed 1st before showing completion modal
    const modalWatcher = setInterval(() => {
      __DEV__ && console.log(`modal check running... ${tms}`)
      if (!document.querySelector('[data-key="max-modal"]') || cancelAction) {
        clearInterval(modalWatcher)
        !cancelAction && callback()
      }
    }, 50)
    watchers.push(modalWatcher)

    return () => {
      watchers.map(watcher => clearInterval(watcher))
    }
  }, variables)
}

export default useEnsureNoDisplayedModal
