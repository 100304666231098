import React, { useEffect } from 'react'
import { Container } from './style'
import { LoadingModal, WarningModal } from '@components/Modal'
import { GET_USER_PROFILE } from '@queries/userProfile'
import { GetUserProfile } from '@interfaces/UserProfile'
import useCustomLazyQuery from '@hooks/useCustomLazyQuery'
import PortalStudentProfile from '@classes/StudentProfile'
import { StoreProps } from '@interfaces/StoreState'
import { useSelector } from 'react-redux'
import { PROFILE_TYPE } from '@utils/constants'
import { useIntl } from 'react-intl'
import PlacementTestMessage from '@berlitzplatforms/micro.ui.placement-test-message'
import COPT from '@classes/OnlinePlacemenTest'
import { useMutation } from '@apollo/react-hooks'
import { POST_LEVEL_CHANGE } from '@mutations/levelChange'
import { usePortalFeatures } from '@components/RoleBasedView'

interface LevelChangeResponse {
  postLevelChange: {
    Success: boolean
    StatusCode: number
  }
}

const OnlinePlacementTest: React.FC<{ programId?: string; registrationId?: string; isBOD1?: boolean }> = ({
  programId,
  registrationId,
  isBOD1,
}) => {
  const intl = useIntl()
  const portalStudentInfo = useSelector(
    ({ userProfile, dashboard }: StoreProps) =>
      new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo, dashboard?.activeMaterial ?? '')
  )
  const [OPTModal, setOPTModal] = React.useState(false)
  const [OPTModalTitle, setOPTModalTitle] = React.useState('Checking registration...')
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [getUserProfile, { data, loading }] = useCustomLazyQuery<GetUserProfile>(GET_USER_PROFILE)
  const [postLevelChange] = useMutation<LevelChangeResponse>(POST_LEVEL_CHANGE, {
    onCompleted: (data) => {
      if (data?.postLevelChange?.Success) {
        localStorage.setItem('activeOPT', '')
        window.location.reload()
      }
    },
    onError: () => {
      setOPTModal(false)
      setTimeout(() => {
        setOpenErrorModal(true)
      })
    },
  })

  const { state } = usePortalFeatures({
    configId: 'flag::OPT-beginnerLevel',
  })

  let buttonLabel2 = ''
  let message2 = ''
  if (state.allowed) {
    buttonLabel2 = intl.formatMessage({
      id: 'Start level 1',
    })

    message2 = intl.formatMessage({
      id: 'Or start learning at level 1.',
    })
  }

  /**
   * When onClick is triggered, We need to validate if the student is still required to take the OPT
   * (will be calculated base from the recent data)
   */
  const optDetails = (value?: GetUserProfile) => {
    if (value?.getUserProfile?.StudentProfile?.StudentProfileInfo) {
      if (programId) {
        const profile = new PortalStudentProfile(value?.getUserProfile?.StudentProfile?.StudentProfileInfo)
        const opt = new COPT(profile.student, undefined, profile.Programs[programId])
        return {
          isRequired: Boolean(opt?.IsRequired),
          url: opt.URL || '',
          activeId: programId,
        }
      } else {
        const profile2 = new PortalStudentProfile(value?.getUserProfile?.StudentProfile?.StudentProfileInfo)
        const registration = profile2?.student?.RegistrationWrappers?.find(
          ({ RegistrationInfo }) => RegistrationInfo?.RegistrationId === registrationId
        )
        const opt2 = new COPT(profile2.student, registration)
        return {
          isRequired: Boolean(opt2?.IsRequired),
          url: opt2.URL || '',
          activeId: registration?.RegistrationInfo?.Materials?.[0]?.LPID || '',
        }
      }
    }

    return {
      isRequired: false,
      url: '',
    }
  }

  /**
   * We are using useEffect here, since we need to trigger an external function
   * window.open
   * (will be calculated after rendering the component)
   */
  useEffect(() => {
    if (data && !loading) {
      const opt = optDetails(data)
      if (!opt?.isRequired) {
        localStorage.setItem('activeOPT', opt?.activeId || '')
        window.location.reload()
      } else {
        window.open(opt?.url, '_blank')
        setOPTModal(false)
      }
    }
  }, [data, loading])

  return (
    <Container hideButtons={isBOD1}>
      <PlacementTestMessage
        title={intl.formatMessage({
          id: 'Test your language skills',
        })}
        message={intl.formatMessage({
          id: isBOD1
            ? 'Return to the dashboard to take the placement test to identify your language level and start learning.'
            : 'Identify your current level of knowledge before you start learning.',
        })}
        message2={message2}
        buttonLabel={intl.formatMessage({
          id: 'Start placement test',
        })}
        onBtnClick={() => {
          if (isBOD1) {
            return
          }
          /**
           * 1. Show loading modal
           * 2. Refetch student profile
           */

          setOPTModal(true)
          setOPTModalTitle('Checking registration...')
          getUserProfile({
            Type: PROFILE_TYPE.STUDENT,
            Id: portalStudentInfo?.SFId,
          })
        }}
        buttonLabel2={buttonLabel2}
        onBtnClick2={() => {
          if (isBOD1) {
            return
          }
          /**
           * 1. Show loading modal
           * 2. Call level change API
           */

          setOPTModal(true)
          setOPTModalTitle('Loading...')
          let regId = registrationId
          if (!registrationId && programId) {
            const registration = portalStudentInfo.getRegistrationByProgramId(programId)
            regId = registration?.RegistrationInfo?.RegistrationId
          }
          const params = {
            RegistrationId: regId,
            Level: '1',
            Origin: 'Portal',
          }

          postLevelChange({
            variables: {
              LevelChangeInput: [params],
            },
          })
        }}
      />
      <LoadingModal
        open={OPTModal}
        onClose={() => {}}
        title={`${intl.formatMessage({
          id: OPTModalTitle,
        })}`}
      />

      <WarningModal open={openErrorModal} onClose={() => setOpenErrorModal(false)} anApiError noButtons />
    </Container>
  )
}

export default OnlinePlacementTest
