import gql from 'graphql-tag'

export const PUT_REGISTRATION_FLEX_LEVEL_STATUS = gql`
  mutation putRegistrations($registrationInput: [RegistrationInput!]!) {
    putRegistrations(registrationInput: $registrationInput) {
      ServiceStatus {
        StatusCode
      }
    }
  }
`

export const PUT_REGISTRATION_MATERIALS = gql`
  mutation putRegistrationMaterials($registrationMaterialInput: [RegistrationMaterialInput!]!) {
    putRegistrationMaterials(registrationMaterialInput: $registrationMaterialInput) {
      ServiceStatus {
        StatusCode
      }
    }
  }
`
