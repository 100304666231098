import React from 'react'
import { useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'

interface Permission {
  key: string
  value: boolean
  config: string
}

const useCTPermission = () => {
  const { userPermissions, profile } = useSelector(({ permissions, userProfile }: StoreProps) => ({
    userPermissions: permissions.permissions,
    profile: userProfile.info,
  }))

  const permissions = {
    'Berlitz Flex - Private Online Activation': {
      key: 'Berlitz Flex - Private Online Activation',
      value: userPermissions['flag::CT-feature-flex']?.show,
      config: 'flag::CT-feature-flex',
    },
    'Berlitz On Demand': {
      key: 'Berlitz On Demand',
      value: userPermissions['flag::CT-feature-flex']?.show,
      config: 'flag::CT-feature-flex',
    },
    default: {
      key: 'default',
      value: userPermissions['flag::CT-feature']?.show,
      config: 'flag::CT-feature',
    },
  }

  const getPermissionObjByProgramType = (programType: string | undefined): Permission => {
    return permissions[programType ?? 'default'] ?? permissions.default
  }

  const getConfigByProgramType = (programType: string | undefined): string => {
    const permission = getPermissionObjByProgramType(programType)
    return permission.config
  }

  const getPermissionByProgramType = (programType: string | undefined): boolean => {
    const permission = getPermissionObjByProgramType(programType)
    return permission.value
  }

  const getPermissionInStudentPrograms = (): boolean => {
    const programs = profile.StudentProfile.StudentProfileInfo.RegistrationWrappers.map(
      registration => registration.RegistrationInfo.ProgramType
    )

    return programs.reduce((acc, curr) => {
      const permission = getPermissionObjByProgramType(curr)
      if (permission.value) {
        acc = true
      }
      return acc
    }, false)
  }

  return { getConfigByProgramType, getPermissionByProgramType, getPermissionInStudentPrograms }
}

export const withHooksHOC = (Component: React.ComponentType) => {
  return (props: any) => {
    const permissions = useCTPermission()
    const newPerops = {
      ...props,
      ...permissions,
    }

    return <Component {...newPerops} />
  }
}

export default useCTPermission
