import PortalLearningPath from '@classes/LearningPath'
import PortalStudentProfile from '@classes/StudentProfile'
import useCTCardState from '@hooks/useCTCardState'
import { RegistrationMaterial, RegistrationWrapper } from '@interfaces/Student'
import { CURRICULUM_TEST_STATUS } from '@utils/constants'
import React, { useEffect } from 'react'

export type ToggleAction = () => void

/**
 * a hook that will help to identify
 * if the student already viewed the CT modal
 */
const useCheckCTShowModal = (
  defaultOpenModal: boolean,
  localStorageKey: string,
  Material: RegistrationMaterial | undefined,
  StudentProfile: PortalStudentProfile | undefined,
  Registration: RegistrationWrapper | undefined
) => {
  const [modal, setModal] = React.useState(false)
  const [getCTState, CTState] = useCTCardState()

  const key = localStorage.getItem(localStorageKey ?? '') !== 'true'

  /**
   * When defaultOpenModal and key are true, we need call API to determine
   * if the modal has been already shown to other browsers or devices
   */
  useEffect(() => {
    if (defaultOpenModal && key && StudentProfile && Registration) {
      getCTState({
        username: StudentProfile?.SFId,
        url: '',
        registration: Registration.RegistrationInfo,
        learningPath: {} as PortalLearningPath,
      })
    } else if (defaultOpenModal) {
      setModal(defaultOpenModal)
    }
  }, [defaultOpenModal, key, StudentProfile?.SFId, Registration?.RegistrationInfo])

  /**
   * When API returns data, we need to make sure
   * that we are working on a material that has test and to only
   * show modal if registration is unlocked and attempt is still 0
   */
  useEffect(() => {
    if (CTState.data) {
      const withTest = Material?.HasTest || Material?.IsTest
      const attempt = CTState?.data?.attempt ?? 10
      const showModal = withTest ? attempt < 1 && Material?.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED : true
      setModal(showModal)
    }
  }, [CTState.data])

  return modal
}

export default useCheckCTShowModal
