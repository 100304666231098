import MaintenanceBanner from '@berlitzplatforms/micro.ui.maintenance-banner'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface EmptyLearningPathV2Props {
  title?: React.ReactNode
  description?: React.ReactNode
}

const EmptyLearningPathV2: React.FC<EmptyLearningPathV2Props> = ({ title, description }) => {
  const refreshPage = () => window.location.reload()
  return (
    <MaintenanceBanner
      header={
        title || (
          <FormattedMessage
            id="Something went wrong loading your learning path"
            defaultMessage="Something went wrong loading your learning path"
          />
        )
      }
      subHeader={
        description || (
          <FormattedMessage
            id="We’re sorry for the inconvenience. Check again in a few minutes, or try reloading the page now."
            defaultMessage="We’re sorry for the inconvenience. Check again in a few minutes, or try reloading the page now."
          />
        )
      }
      btnText={<FormattedMessage id="Reload" defaultMessage="Reload" />}
      onButtonClick={refreshPage}
    />
  )
}

export default EmptyLearningPathV2
