import { FormattedMessage } from 'react-intl'
import React from 'react'
import MaintenanceBanner from '@berlitzplatforms/micro.ui.maintenance-banner'

const PreparingNxtLvlBanner = () => {
  const refreshPage = () => window.location.reload()
  return (
    <MaintenanceBanner
      header={
        <FormattedMessage
          id="Your next level isn't quite ready yet."
          defaultMessage="Your next level isn't quite ready yet."
        />
      }
      subHeader={
        <FormattedMessage
          id="Sorry for the inconvenience. Come back a little later, or try reloading the page."
          defaultMessage="Sorry for the inconvenience. Come back a little later, or try reloading the page."
        />
      }
      btnText={<FormattedMessage id="Reload" defaultMessage="Reload" />}
      onButtonClick={refreshPage}
    />
  )
}

export default PreparingNxtLvlBanner
