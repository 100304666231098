import gql from 'graphql-tag'

export const GET_ACHIEVEMENTS = gql`
  query GetAchievements($studentProfileId: String!) {
    getAchievements(studentProfileId: $studentProfileId) {
      data {
        achievementInfo {
          Id
          Type
          TemplateName
          StudentProfile {
            FirstName
            LastName
            PortalLanguage
          }
          SignedBy {
            DisplayName
            DisplayTitle
            DisplaySignatureURL
          }
          Registration {
            RegistrationMaterials {
              MaterialName
              Id
              IsTest
              IsElective
              HasTest
            }
            Program {
              Id
              StartDate
              EndDate
            }
            Id
          }
          ReferenceId
          IssueDate
        }
      }
    }
  }
`
