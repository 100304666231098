import Spacer from '@berlitz/spacer'
import LockUnlock1 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/LockUnlock1'
import Lock1 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/Lock1'
import InformationCircle from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/14-Alerts/InformationCircle'
import LearningPathUnit from '@classes/LearningPathUnit'
import Button from '@components/Button'
import {
  Card,
  CardContent,
  Float,
  Icon,
  IconCircle,
  Subtitle,
  SelectorTitle,
  Title,
  IPType,
  CardHeader,
} from '@components/LPCard/Common'
import Select from '@components/Select'
import Router from 'next/router'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TopTag } from '../Lesson/style'
import { Description, SubContainer } from './style'
import PortalLearningPath from '@classes/LearningPath'

interface IPUnlockedMultiple {
  ActiveUnits?: LearningPathUnit[]
  CardType?: IPType
  Path?: PortalLearningPath
}

export const UnlockedMultiple: React.FC<IPUnlockedMultiple> = ({
  ActiveUnits = [],
  CardType = 'speakingPanel',
  Path,
}) => {
  const intl = useIntl()
  const unitsDD = ActiveUnits.map((unit) => ({
    id: unit.Id,
    label: unit.LCSTitle?.replace('Live ', ''),
    value: unit.Id,
  }))

  const [selectedLCS, setSelectedLCS] = useState<number>()

  const bookingHandler = () => {
    const selectedUnit = ActiveUnits.filter((unit) => unit.Id === selectedLCS)[0]
    Router.push(
      `/scheduling/single-lesson?programid=${selectedUnit?.StudentProgram?.ProgramId}&unitId=${selectedLCS}&LPId=${selectedUnit.LPId}`
    )
  }

  const isDisabled = !Path?.IsActiveFlexRegistration
  return (
    <Card type={CardType} active disabled={isDisabled}>
      <CardContent isElective={ActiveUnits?.[0]?.IsFromElective}>
        <CardHeader>
          {ActiveUnits?.[0]?.IsFromElective && (
            <TopTag color="info100">
              <FormattedMessage id="Elective" defaultMessage="Elective" />
            </TopTag>
          )}
          {CardType === 'speakingPanel' ? (
            <Icon color="white">
              <IconCircle color="info100" />
              {isDisabled ? <Lock1 /> : <LockUnlock1 />}
            </Icon>
          ) : (
            <Icon color="white">
              <IconCircle color="white" opacity="0.27" />
              {isDisabled ? <Lock1 /> : <LockUnlock1 />}
            </Icon>
          )}
        </CardHeader>

        <Spacer size="md" />

        <Title color="info100">
          <FormattedMessage id="Live coaching sessions unlocked!" />
        </Title>

        <Spacer size="xxxs" />
        <Spacer size="sm" />

        <SelectorTitle>
          <FormattedMessage id="Select session" />
        </SelectorTitle>
        <Spacer size="xxs" />
        {ActiveUnits?.length > 1 ? (
          <>
            <Select
              placeholderText={intl.formatMessage({ id: 'Select session' })}
              options={unitsDD}
              // @ts-ignore
              defaultValue="Select session"
              onChange={(unitID) => setSelectedLCS(unitID)}
              disableMargin
              fullWidth
              disabled={isDisabled}
            />
            <Spacer size="sm" />
          </>
        ) : null}

        <Spacer size="sm" />

        <Subtitle>
          <Float>
            <Button disabled={!selectedLCS} color="success" narrow onClick={bookingHandler}>
              <FormattedMessage id="Book" />
            </Button>
          </Float>
        </Subtitle>

        <Spacer size="md" />

        <SubContainer color="info30" className="sub-container">
          <InformationCircle />
          <Description color="text00">
            <FormattedMessage id="Practice what you've learnt with an instructor, online via video. (approx. 30 minutes)" />
          </Description>
        </SubContainer>
        <Spacer size="xs" />
      </CardContent>
    </Card>
  )
}

export default UnlockedMultiple
