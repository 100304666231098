import Lesson from '@components/LPCard/Lesson'
import { FlexChapterProps } from '@layouts/LearningPath/Chapter/Flex/index'
import { LP_STATUS } from '@utils/constants/learningPath'
import React from 'react'

const FlexLesson: React.FC<FlexChapterProps> = ({ Unit, active }) => {
  if (Unit.Status === LP_STATUS.NOT_YET_STARTED) {
    return (
      <Lesson.Start
        active={active}
        title={Unit.HeaderTitle}
        fullname={Unit.BodyTitleClean}
        link={Unit.EntryPointUrl}
        unit={Unit}
      />
    )
  }

  if (Unit.Status === LP_STATUS.IN_PROGRESS) {
    return (
      <Lesson.InProgress
        active={active}
        title={Unit.HeaderTitle}
        fullname={Unit.BodyTitleClean}
        activities={Unit.Rounds}
        link={Unit.EntryPointUrl}
        unit={Unit}
      />
    )
  }

  return (
    <Lesson.Completed
      active={active}
      title={Unit.HeaderTitle}
      fullname={Unit.BodyTitleClean}
      activities={Unit.Rounds}
      link={Unit.ResetUrl}
      isRedo={Unit.IsRedo}
      continueLink={Unit.EntryPointUrl}
      unit={Unit}
    />
  )
}

export default FlexLesson
