import React from 'react'

export interface ImageUnsuccessfulProps extends React.SVGProps<SVGSVGElement> {
  title?: string
}

const ImageUnsuccessful: React.FC<ImageUnsuccessfulProps> = (props) => (
  <svg {...props} width="242" height="183.569" viewBox="0 0 242 183.569" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          d="M14.76 130.336c0-3.485-2.85-11.753-6.363-11.753-3.513 0-6.361 8.268-6.361 11.753 0 3.487 2.848 6.314 6.361 6.314 3.514 0 6.362-2.827 6.362-6.314z"
          fill="#643BA2"
        />
        <path
          d="M8.397 144.538c-.14 0-.254-.088-.254-.198v-17.67c0-.11.114-.199.254-.199.141 0 .255.089.255.198v17.671c0 .11-.114.198-.255.198z"
          fill="#CCC"
        />
        <path
          d="M8.329 129.525a.186.186 0 0 1-.132-.317l2.428-2.428a.186.186 0 0 1 .263.262L8.46 129.47a.185.185 0 0 1-.131.055z"
          fill="#CCC"
        />
        <path
          d="M29.666 75.832h-3.094c-.48 0-.87-.39-.87-.872v-7.163c0-.481.39-.871.87-.872h3.094c.48 0 .87.39.87.872v7.163c0 .481-.39.871-.87.872z"
          fill="#99B9F9"
        />
        <path
          d="M42.086 144.03h-16.23a1.682 1.682 0 0 1-1.681-1.679v-65.35a1.682 1.682 0 0 1 1.682-1.678h16.23A1.682 1.682 0 0 1 43.768 77v65.35a1.682 1.682 0 0 1-1.683 1.678z"
          fill="#99B9F9"
        />
        <path
          d="M32.671 144.03h-16.23a1.682 1.682 0 0 1-1.682-1.679v-65.35a1.682 1.682 0 0 1 1.683-1.678H32.67A1.682 1.682 0 0 1 34.353 77v65.35a1.682 1.682 0 0 1-1.682 1.678z"
          fill="#B2CAFA"
        />
        <path
          d="M26.612 75.832h-3.094c-.48 0-.87-.39-.87-.872v-7.163c0-.481.39-.871.87-.872h3.094c.48 0 .87.39.87.872v7.163c0 .481-.39.871-.87.872z"
          fill="#B2CAFA"
        />
        <path
          d="M22.399 90.336H18.57a.496.496 0 0 1-.504-.486v-5.643c0-.269.226-.486.504-.487h3.828c.278 0 .503.218.503.487v5.643a.496.496 0 0 1-.503.486zM30.033 90.336h-3.828a.496.496 0 0 1-.504-.486v-5.643c0-.269.226-.486.504-.487h3.828c.278 0 .503.218.503.487v5.643a.496.496 0 0 1-.503.486zM22.399 101.024H18.57a.496.496 0 0 1-.504-.486v-5.644c0-.268.226-.486.504-.486h3.828c.278 0 .503.218.503.486v5.644a.496.496 0 0 1-.503.486zM30.033 101.024h-3.828a.496.496 0 0 1-.504-.486v-5.644c0-.268.226-.486.504-.486h3.828c.278 0 .503.218.503.486v5.644a.496.496 0 0 1-.503.486zM22.399 111.457H18.57a.496.496 0 0 1-.504-.486v-5.643c0-.269.226-.486.504-.487h3.828c.278 0 .503.218.503.487v5.643a.496.496 0 0 1-.503.486zM30.033 111.457h-3.828a.496.496 0 0 1-.504-.486v-5.643c0-.269.226-.486.504-.487h3.828c.278 0 .503.218.503.487v5.643a.496.496 0 0 1-.503.486zM22.399 122.145H18.57a.496.496 0 0 1-.504-.486v-5.644c0-.268.226-.486.504-.486h3.828c.278 0 .503.218.503.486v5.644a.496.496 0 0 1-.503.486zM30.033 122.145h-3.828a.496.496 0 0 1-.504-.486v-5.644c0-.268.226-.486.504-.486h3.828c.278 0 .503.218.503.486v5.644a.496.496 0 0 1-.503.486zM22.399 132.07H18.57a.496.496 0 0 1-.504-.487v-5.643c0-.269.226-.486.504-.487h3.828c.278 0 .503.218.503.487v5.643a.496.496 0 0 1-.503.486zM30.033 132.07h-3.828a.496.496 0 0 1-.504-.487v-5.643c0-.269.226-.486.504-.487h3.828c.278 0 .503.218.503.487v5.643a.496.496 0 0 1-.503.486z"
          fill="#FFF"
        />
        <path
          d="M206.432 144.538H.197c-.109 0-.197-.114-.197-.254s.088-.255.197-.255h206.235c.109 0 .197.114.197.255 0 .14-.088.254-.197.254z"
          fill="#A1A2A8"
        />
        <path d="M175.838 144.03c.001-1.969 1.482-3.563 3.308-3.563 1.826 0 3.307 1.594 3.308 3.562" fill="#F2F2F2" />
        <path
          d="M179.146 144.03c-.13 0-.236-.043-.236-.095v-1.847c0-.052.106-.094.236-.094s.236.042.236.094v1.847c0 .052-.105.094-.236.094z"
          fill="#CCC"
        />
        <path
          d="M179.292 143.52a.13.13 0 0 1-.071-.02l-1.308-.851c-.03-.02-.044-.05-.037-.079.007-.029.034-.053.071-.063a.136.136 0 0 1 .108.016l1.308.852c.034.023.047.06.03.092-.015.032-.056.054-.101.054z"
          fill="#CCC"
        />
        <path
          d="M200.522 130.336c0-3.485-2.849-11.753-6.362-11.753-3.514 0-6.362 8.268-6.362 11.753 0 3.487 2.848 6.314 6.362 6.314 3.513 0 6.362-2.827 6.362-6.314z"
          fill="#643BA2"
        />
        <path
          d="M194.669 144.538c-.14 0-.255-.088-.255-.198v-17.67c0-.11.114-.199.255-.199.14 0 .254.089.254.198v17.671c0 .11-.114.198-.254.198z"
          fill="#CCC"
        />
        <path
          d="M194.6 129.525a.186.186 0 0 1-.131-.317l2.428-2.428a.186.186 0 0 1 .262.262l-2.428 2.428a.185.185 0 0 1-.131.055z"
          fill="#CCC"
        />
        <path
          d="M215.428 92.881h-3.094c-.48 0-.87-.39-.87-.871v-7.164c0-.48.39-.87.87-.871h3.094c.48 0 .87.39.87.871v7.164c0 .48-.39.87-.87.871z"
          fill="#99B9F9"
        />
        <path
          d="M227.849 144.03h-16.23a1.679 1.679 0 0 1-1.682-1.671V94.043a1.679 1.679 0 0 1 1.682-1.67h16.23a1.679 1.679 0 0 1 1.682 1.67v48.316a1.679 1.679 0 0 1-1.682 1.67z"
          fill="#99B9F9"
        />
        <path
          d="M218.433 144.03h-16.23a1.679 1.679 0 0 1-1.681-1.671V94.043a1.679 1.679 0 0 1 1.682-1.67h16.23a1.679 1.679 0 0 1 1.682 1.67v48.316a1.679 1.679 0 0 1-1.683 1.67z"
          fill="#B2CAFA"
        />
        <path
          d="M212.375 92.881h-3.095c-.48 0-.87-.39-.87-.871v-7.164c0-.48.39-.87.87-.871h3.095c.48 0 .87.39.87.871v7.164c0 .48-.39.87-.87.871z"
          fill="#B2CAFA"
        />
        <path
          d="M208.16 105.096h-3.827a.496.496 0 0 1-.503-.487v-5.643c0-.269.225-.486.503-.487h3.828c.278 0 .503.218.504.487v5.643a.496.496 0 0 1-.504.487zM215.795 105.096h-3.828a.496.496 0 0 1-.503-.487v-5.643c0-.269.225-.486.503-.487h3.828c.278 0 .503.218.504.487v5.643a.496.496 0 0 1-.504.487zM208.16 115.529h-3.827a.496.496 0 0 1-.503-.487V109.4c0-.268.225-.486.503-.486h3.828c.278 0 .503.218.504.486v5.643a.496.496 0 0 1-.504.487zM215.795 115.529h-3.828a.496.496 0 0 1-.503-.487V109.4c0-.268.225-.486.503-.486h3.828c.278 0 .503.218.504.486v5.643a.496.496 0 0 1-.504.487zM208.16 126.217h-3.827a.496.496 0 0 1-.503-.487v-5.643c0-.269.225-.486.503-.487h3.828c.278 0 .503.218.504.487v5.643a.496.496 0 0 1-.504.487zM215.795 126.217h-3.828a.496.496 0 0 1-.503-.487v-5.643c0-.269.225-.486.503-.487h3.828c.278 0 .503.218.504.487v5.643a.496.496 0 0 1-.504.487zM208.16 136.14h-3.827a.496.496 0 0 1-.503-.486v-5.643c0-.268.225-.486.503-.486h3.828c.278 0 .503.218.504.486v5.643a.496.496 0 0 1-.504.487zM215.795 136.14h-3.828a.496.496 0 0 1-.503-.486v-5.643c0-.268.225-.486.503-.486h3.828c.278 0 .503.218.504.486v5.643a.496.496 0 0 1-.504.487z"
          fill="#FFF"
        />
        <path
          d="M241.921 144.538h-82.544c-.044 0-.08-.114-.08-.254s.036-.255.08-.255h82.544c.044 0 .079.114.079.255 0 .14-.035.254-.079.254z"
          fill="#A1A2A8"
        />
        <path
          d="M240.219 130.336c0-3.485-2.849-11.753-6.362-11.753s-6.362 8.268-6.362 11.753c0 3.487 2.849 6.314 6.362 6.314s6.362-2.827 6.362-6.314z"
          fill="#643BA2"
        />
        <path
          d="M233.603 144.538c-.141 0-.255-.088-.255-.198v-17.67c0-.11.114-.199.255-.199.14 0 .254.089.254.198v17.671c0 .11-.114.198-.254.198z"
          fill="#CCC"
        />
        <path
          d="M233.534 129.525a.186.186 0 0 1-.132-.317l2.428-2.428a.186.186 0 0 1 .263.262l-2.428 2.428a.185.185 0 0 1-.131.055z"
          fill="#CCC"
        />
      </g>
      <g transform="translate(29.968)">
        <ellipse fill="#CCDCFC" cx="97.521" cy="102.169" rx="76.341" ry="76.213" />
        <path
          d="M52.956 88.625a6.242 6.242 0 0 0 .796-3.115c0-2.816-1.708-5.098-3.816-5.098-2.108 0-3.817 2.282-3.817 5.098a6.242 6.242 0 0 0 .797 3.115 6.49 6.49 0 0 0 0 6.23 6.49 6.49 0 0 0 0 6.23 6.49 6.49 0 0 0 0 6.231 6.242 6.242 0 0 0-.797 3.115c0 2.816 1.709 5.098 3.817 5.098s3.816-2.282 3.816-5.098a6.242 6.242 0 0 0-.796-3.115 6.49 6.49 0 0 0 0-6.23 6.49 6.49 0 0 0 0-6.23 6.49 6.49 0 0 0 0-6.231z"
          fill="#3F3D56"
          fillRule="nonzero"
        />
        <path
          d="M64.51 30.197a18.7 18.7 0 0 0 1.453-2.135l-10.26-1.681 11.097.082a18.652 18.652 0 0 0 .357-14.785L52.27 19.383 66 9.314A18.715 18.715 0 0 0 48.906.022a18.705 18.705 0 0 0-16.114 10.893 18.625 18.625 0 0 0 2.315 19.282 18.663 18.663 0 0 0-2.133 3.403l13.318 6.903-14.2-4.755a18.635 18.635 0 0 0 3.015 17.523c-6.031 7.65-5.156 18.638 2.01 25.242 7.166 6.604 18.215 6.604 25.381 0s8.041-17.593 2.01-25.242a18.628 18.628 0 0 0 0-23.074z"
          fill="#004FEF"
        />
        <path
          d="M152.476 152.357c-4.837 17.23-42.156 33.211-81.841 22.077-39.686-11.135-66.71-45.168-61.873-62.399 4.837-17.23 39.702-11.133 79.387.001 39.686 11.135 69.163 23.09 64.327 40.32z"
          fill="#3A3A47"
        />
        <ellipse
          fill="#4D4E5A"
          transform="rotate(-74.324 80.628 132.204)"
          cx="80.628"
          cy="132.204"
          rx="32.4"
          ry="74.65"
        />
        <ellipse fill="#3A3A47" cx="58.333" cy="133.342" rx="29.009" ry="6.362" />
        <path
          d="M63.12 109.379a.48.48 0 0 1 0-.679l17.752-17.75a2.876 2.876 0 0 0-4.07-4.064l-17.75 17.742a.48.48 0 0 1-.678 0L40.625 86.886a2.875 2.875 0 1 0-4.066 4.065l17.747 17.75a.48.48 0 0 1 0 .678L36.559 127.13a2.875 2.875 0 0 0 4.066 4.065l17.75-17.751a.48.48 0 0 1 .678 0L76.8 131.195a2.875 2.875 0 1 0 4.067-4.065L63.12 109.38z"
          fill="#FF495C"
        />
        <path d="M10.748 86.01c0 8.58 5.349 15.523 11.96 15.523" fill="#3F3D56" fillRule="nonzero" />
        <path
          d="M22.708 101.533c0-8.72 6.032-15.777 13.486-15.777M15.074 86.774c0 8.158 3.414 14.76 7.634 14.76"
          fill="#004FEF"
        />
        <path d="M22.708 101.533c0-11.112 6.942-20.103 15.522-20.103" fill="#3F3D56" fillRule="nonzero" />
        <path
          d="M20.163 101.643s1.71-.05 2.225-.397c.515-.346 2.63-.76 2.758-.204.127.556 2.569 2.766.639 2.78-1.93.016-4.485-.283-5-.58-.514-.296-.622-1.599-.622-1.599z"
          fill="#A8A8A8"
          fillRule="nonzero"
        />
        <path
          d="M25.82 103.874c-1.93.016-4.486-.298-5-.609-.392-.237-.548-1.086-.6-1.477a5.886 5.886 0 0 1-.057.002s.108 1.368.622 1.678c.515.311 3.07.625 5 .61.557-.005.75-.202.74-.493-.078.176-.29.286-.705.29z"
          fill="#3A3A47"
          fillRule="nonzero"
        />
        <g transform="translate(122.2 46.059)">
          <circle fill="#6C63FF" fillRule="nonzero" cx=".895" cy="48.476" r="1" />
          <circle fill="#6C63FF" fillRule="nonzero" cx="1.404" cy="47.204" r="1" />
          <circle fill="#6C63FF" fillRule="nonzero" cx="1.658" cy="46.441" r="1" />
          <path
            d="m17.777 91.164.55 1.633-1.794 1.776-5.908 1.362-4.259-.409.276-1.497a1.814 1.814 0 0 1 1.353-1.32c1.75-.406 5.089-1.337 5.79-2.627l3.992 1.082z"
            fill="#B87777"
            fillRule="nonzero"
          />
          <path
            d="m17.777 91.164.55 1.633-1.794 1.776-5.908 1.362-4.259-.409.276-1.497a1.814 1.814 0 0 1 1.353-1.32c1.75-.406 5.089-1.337 5.79-2.627l3.992 1.082z"
            fill="#D19166"
            fillRule="nonzero"
          />
          <path
            d="m7.677 95.305.837-1.173-.837-1.36s-2.51 1.066-3.625.92c-1.116-.147-1.673 1.914.418 2.494 2.092.58 13.944 0 13.944 0s2.092-1.027 0-3.814l-1.82 1.468s-3.067 1.76-4.6 1.467l-4.317-.002z"
            fill="#1D1D1D"
          />
          <path
            d="m4.076 97.562 4.26.409 5.907-1.36 1.793-1.776-.145-.432-.206-.607-.205-.59-3.984-1.088a2.456 2.456 0 0 1-.815.807c-1.294.876-3.619 1.508-4.983 1.825a1.44 1.44 0 0 0-.163.05 1.805 1.805 0 0 0-1.184 1.268l-.275 1.494z"
            fill="#F1B892"
            fillRule="nonzero"
          />
          <path
            d="m4.076 97.788 4.26.437 5.907-1.455 1.793-1.9-.145-.462-1.64 1.336S11.229 97.49 9.718 97.2H5.45l.825-1.164-.74-1.208a1.884 1.884 0 0 0-1.184 1.356l-.275 1.604z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m5.386 97.341.838-1.173-.838-1.36s-2.51 1.066-3.625.92C.646 95.58.09 97.641 2.18 98.221c2.091.58 13.944 0 13.944 0s2.092-1.027 0-3.814l-1.82 1.467s-3.067 1.76-4.6 1.468l-4.318-.002z"
            fill="#1D1D1D"
          />
          <path
            d="M8.91 16.21s6.617.688 5.652-2.067a4.962 4.962 0 0 1-.204-2.434 6.746 6.746 0 0 1 1.169-2.803l-6.203.965c.372.622.655 1.292.841 1.992.74 2.922-1.254 4.347-1.254 4.347z"
            fill="#DB8B8B"
            fillRule="nonzero"
          />
          <path
            d="M20.107 48.095s-.14 6.01-1.542 8.945l-.282 8.526s.421 7.827 1.264 9.365c.842 1.537-1.831 14.535-1.831 14.535v2.238s-4.493 1.676-5.752-.28l1.264-16.634-.561-23.062 7.44-3.633z"
            fill="#51327F"
          />
          <path
            d="m23.104 28.755 1.542 6.982a4.19 4.19 0 0 1-1.262 3.63c-1.681 1.675-3.924 5.165-3.924 5.165l-2.66-7.951s2.66-.42 2.242-2.235c-.417-1.816-.282-5.179-.282-5.179l4.344-.412z"
            fill="#FF495C"
          />
          <path
            d="M5.094 53.64c.092 1.3 1.475 6.268 4.15 14.901A46.808 46.808 0 0 1 11.07 81.26c0 1.426.13 2.898.53 4.003 1.107 3.065-1.106 7.245-1.106 7.245 1.937 3.483 5.254 1.114 5.254 1.114v-2.228s2.627-12.12 1.938-14.488c-.69-2.367-.97-8.777-.97-8.777v-11.57c4.527-2.918 3.858-9.254 3.876-9.751.112-3.14-2.023-6.828-2.857-8.14-.2-.318-.328-.497-.328-.497l-12.22 4.88-.028.011-.064 10.58z"
            fill="#643BA2"
          />
          <circle fill="#F1B892" fillRule="nonzero" cx="12.092" cy="9.288" r="5.471" />
          <path
            d="m5.348 44.362.059.012c5.728 1.209 12.92-4.917 12.92-4.917-.194-.333-.823-1.541-.823-1.541L5.442 42.824l-.094 1.538z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="M6.58 16.465s7.719-4.7 10.2-2.765l6.89 17.555-4.961 1.245s-2.895 2.764-.551 6.772c0 0-7.305 6.081-13.093 4.838l.413-6.496s1.242-3.593-.964-6.91c-2.206-3.318-.138-6.496 1.93-7.74 0-.002.962-5.255.137-6.5z"
            fill="#FF495C"
          />
          <path
            d="M6.168 44.787s-3.01.532-4.445 2.663a8.44 8.44 0 0 1-1.5 1.737c-.265.224-.373 1.228.17 1.684 1.138.632 2.994 1.025 5.338-.763 4.02-3.058.437-5.321.437-5.321z"
            fill="#F1B892"
            fillRule="nonzero"
          />
          <path
            d="M10.334 18.68s2.788-.278 3.485 6.952c.696 7.23 1.819 8.898 1.819 8.898s.696 3.755-.976 5.979c-1.672 2.223-4.879 6.951-4.879 6.951s-3.484.974-4.18-2.502l4.32-8.064s.697-2.781-.975-4.728C7.276 30.22 6.013 17.29 10.334 18.681z"
            fill="#E54152"
            fillRule="nonzero"
          />
          <path
            d="M10.08 18.426s2.788-.279 3.484 6.952c.696 7.23 1.82 8.898 1.82 8.898s.695 3.754-.977 5.978c-1.671 2.224-4.878 6.952-4.878 6.952s-3.485.973-4.18-2.503l4.32-8.063s.697-2.781-.975-4.728c-1.672-1.947-2.93-14.876 1.386-13.486z"
            fill="#FF495C"
          />
          <path
            d="M7.638 23.411s.125 3.773.98 6.785c.854 3.012 1.264 5.512 0 7.21M7.13 18.067s1.144-1.077 4.437-1.213c3.293-.136 4.723-1.077 4.723-1.077"
            fill="#E54152"
            fillRule="nonzero"
          />
          <path
            d="M14.78 1.218c1.487 0 2.427 1.521 2.54 1.812.46 1.17.264.432 2.83 2.387 2.567 1.956 3.296 8.988 3.318 12.17.014 2.33-.256 1.793-.128 6.975.128 5.182 3.64 7.905 4.135 8.774-.71.74-1.787.965-2.8 1.125-1.178.186-2.406.028-3.551.436-1.795.64-2.648-.674-2.923-1.062-.819-1.066-1.16-2.44-1.293-3.783-.134-1.342-.088-2.7-.229-4.044-.076-.732-.877-2.48-2.004-2.977-1.654-.729-2.398-3.174-2.69-5.145-.292-1.972-.21-3.992-.619-5.943-.16-.763-.561-1.655-1.334-1.712-.244-.018-1.201.282-1.66-1.636-.46-1.92-.948-1.153-1.204-1.852-.603-1.625 1.272-3.882 2.59-4.635 1.339-.77 3.51-.89 5.021-.89z"
            fill="#37315B"
          />
        </g>
        <g transform="translate(87.343 58.664)">
          <path
            d="M10.3 90.455s-2.257.671-3.386 2.014c0 0-2.032.895-3.274.671-1.242-.223-3.5 2.014-1.693 2.686 1.806.671 12.87 2.461 13.095 1.902.226-.56.226-6.266.226-6.266l-4.967-1.007zM28.188 89.437s-2.1 1.038-2.977 2.544c0 0-1.84 1.219-3.096 1.208-1.257-.01-3.088 2.563-1.2 2.917 1.886.353 13.047.245 13.173-.341.125-.586-.854-6.177-.854-6.177l-5.046-.15z"
            fill="#737585"
          />
          <circle fill="#FFB9B9" fillRule="nonzero" cx="13.996" cy="7.753" r="5.089" />
          <path
            d="M18.175 8.77s.557 3.768 1.56 4.211c.698.342 1.338.79 1.895 1.33l-2.786 1.33-5.46-.332s.445-2.438-.335-3.657c-.78-1.22 5.126-2.882 5.126-2.882zM28.633 26.838s2.564 6.6 0 12.975c-2.563 6.376-3.678 7.382-3.678 7.382l-.78-8.389.897-.817s.543-2.545.102-4.052c-.442-1.507.227-6.204.227-6.204l3.232-.895z"
            fill="#FFB9B9"
            fillRule="nonzero"
          />
          <path
            d="M9.344 40.956s-3.299 7.482-2.958 11.11c.342 3.628 2.958 18.932 2.958 18.932s-.342 19.725.682 19.725 5.914 1.814 5.914.567 1.024-31.175 1.024-31.175 5.8 13.15 6.369 14.17c.568 1.02 4.208 17.005 5.459 17.005 1.251 0 5.118-1.134 4.777-1.928-.342-.793-4.208-18.478-6.37-23.012-2.16-4.535-1.705-21.653-1.705-21.653l-3.64-7.935-12.51 4.194z"
            fill="#2F2E41"
            fillRule="nonzero"
          />
          <path
            d="M12.597 5.222S9.4 4.993 9.171 4.192c-.228-.8-.685-1.486-.457-1.486.229 0 .343-.572.343-.572s.228-.114.342 0c.115.115.229-.8.229-.8s.114-.343.228-.115c.115.23.115.115.229-.228s.685-.572.685-.572 2.17.114 2.513-.343c.342-.457 4.225-.114 5.025.457.8.572 4.34 2.63 3.769 4.346-.571 1.715-2.627 5.488-2.627 6.06 0 .572-.8.458-1.028-.114-.228-.572-2.056-1.258-2.056-1.601 0-.343-1.028-3.545-1.599-3.316-.57.229-1.256 2.058-1.484 1.715-.229-.343-.686-2.401-.686-2.401z"
            fill="#3DDC97"
            fillRule="nonzero"
          />
          <path
            d="M27.992 20.97s-4.602-6.797-5.95-7.136c-1.346-.34-4.377-1.247-4.377-1.247s-3.03.454-3.928.907c-.898.453-4.265 1.246-4.377 2.605-.113 1.36.898 14.388.336 15.634-.56 1.246-2.469 13.14-1.57 12.914.897-.226.224-3.172 4.152-3.285 3.928-.113 6.06-2.492 7.52.34s5.612 4.984 5.612 4.984c.104-.913.254-1.82.449-2.719.337-1.586-.898-5.437-.898-5.437s.112-8.95.561-9.97c.45-1.019 2.47-7.59 2.47-7.59z"
            fill="#643BA2"
          />
          <path
            d="m26.351 20.476 1.668.23s-.111 2.3.334 2.991c.445.69 1.223 4.256.778 4.371-.445.115-4.448.805-4.448.805l1.668-8.397z"
            fill="#643BA2"
          />
          <path
            d="M8.107 24.802s-1.132 7.302-1.698 8.2c-.566.899-3.397 13.48-3.397 13.48s-4.655 6.422-2.39 6.31c2.264-.112 4.655-5.86 4.655-5.86s7.02-12.694 7.02-14.042c0-1.348 1.699-8.088 1.699-8.088H8.107z"
            fill="#FFB9B9"
            fillRule="nonzero"
          />
          <path
            d="m11.323 14.878-2.059.796S6.634 25 7.206 25.797c.572.796 7.432-.341 7.547-.682.114-.342-1.258-10.237-3.43-10.237z"
            fill="#643BA2"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ImageUnsuccessful
