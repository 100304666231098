import PortalLearningPath from '@classes/LearningPath'
import userflow from 'userflow.js'
import range from 'lodash/range'

export const getLPAttributes = (Path: PortalLearningPath) => {
  const totalLCS = Path.AllLCS.map(unit => unit.IndexOrder)
  const totalLessons = Path.AllFlexBODLessons.map(unit => unit.IndexOrder)
  const totalLOLessons = Path.AllLOLessons.map(unit => unit.IndexOrder)
  const totalCheckpoints = range(1, 5)

  const completedLCS = Path.CompletedLCS.map(unit => unit.IndexOrder)
  const totalCompletedLCS = totalLCS.reduce((acc, curr) => {
    acc[`${curr}_lcs_complete`] = completedLCS.includes(curr) || false
    return acc
  }, {})

  const completedFlexBODLessons = Path.CompletedFlexBODLessons.map(unit => unit.IndexOrder)
  const totalCompletedFlexBODLessons = totalLessons.reduce((acc, curr) => {
    acc[`lesson_complete_${curr}`] = completedFlexBODLessons.includes(curr) || false
    return acc
  }, {})

  const completedLOLessons = Path.CompletedLOLessons.map(unit => unit.IndexOrder)
  const totalCompletedLOLessons = totalLOLessons.reduce((acc, curr) => {
    acc[`lesson_lo_complete_${curr}`] = completedLOLessons.includes(curr) || false
    return acc
  }, {})

  const completedCheckpoints = Path.CompletedCheckpoints.map(unit => unit.IndexOrder)
  const totalCompletedCheckpoints = totalCheckpoints.reduce((acc, curr) => {
    acc[`checkpoint_complete_${curr}`] = completedCheckpoints.includes(curr) || false
    return acc
  }, {})

  const material = Path.Material?.LPExternalName

  return {
    ...totalCompletedLCS,
    ...totalCompletedFlexBODLessons,
    ...totalCompletedLOLessons,
    ...totalCompletedCheckpoints,
    material,
  }
}

export const setUserFlow = (SFId: string, isStudent: boolean, Path?: PortalLearningPath) => {
  if (process.env.USERFLOW_TOKEN && isStudent && Path) {
    const lpAttributes = getLPAttributes(Path)
    userflow.identify(SFId, {
      ...lpAttributes,
      lis_complete: Path.HasCompletedLIS,
      language_learning: Path.Material?.language,
      level: Path.Level?.toString(),
      product_type: Path.Material?.ProgramType,
    })
  }
}
