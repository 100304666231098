import Spacer from '@berlitz/spacer'
import ImageWelcome from '@berlitzplatforms/micro.image.welcome'
import MaintenanceBanner from '@berlitzplatforms/micro.ui.maintenance-banner'
import PortalLearningPath from '@classes/LearningPath'
import { SelectElectiveModal } from '@components/Modal/SelectElectiveModal'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { BtnWrapper, Description, H2, Wrapper } from './style'

interface ElectiveBannerProps {
  Path: PortalLearningPath
}

const ElectiveRequiredBanner: React.FC<ElectiveBannerProps> = ({ Path }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Wrapper>
        <MaintenanceBanner
          header={
            <H2>
              <Spacer size="xs" />
              <FormattedMessage
                id="Welcome to {name}!"
                defaultMessage="Welcome to {name}!"
                values={{ name: Path.Material?.MaterialName }}
              />
            </H2>
          }
          subHeader={
            <Description>
              <FormattedMessage
                id="Before you start your level please select the elective course you'd like to take."
                defaultMessage="Before you start your level please select the elective course you'd like to take."
              />
            </Description>
          }
          btnText={
            <BtnWrapper>
              <FormattedMessage id="Select elective" defaultMessage="Select elective" />
            </BtnWrapper>
          }
          image={<ImageWelcome />}
          onButtonClick={() => {
            localStorage.setItem(Path.tempFlags.SelectElective, 'true')
            setOpen(true)
          }}
        />
      </Wrapper>
      <SelectElectiveModal
        MainMaterialId={Path.Material?.MaterialId || ''}
        StudentProfileId={Path.StudentProfile?.SFId || ''}
        Path={Path}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default ElectiveRequiredBanner
