import styled from 'styled-components'

export const Container = styled.div<{ hideButtons?: boolean }>`
  margin-right: ${({ theme }) => theme.space.xl};
  margin-top: ${({ theme }) => theme.space.xl};
  margin-left: ${({ theme }) => theme.space.xl};
  margin-bottom: ${({ theme }) => theme.space.xl};
  background-color: #f4f8ff;
  padding-left: ${({ theme }) => theme.space.xl};
  padding-top: ${({ theme }) => theme.space.xl};
  padding-bottom: ${({ theme }) => theme.space.lg};
  padding-right: ${({ theme }) => theme.space.xl};
  display: flex;
  justify-content: center;

  ${({ hideButtons }) =>
    hideButtons
      ? `
    button {
      display: none;
    }
  `
      : ''}
`
