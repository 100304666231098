import styled from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => `
    & > div {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: ${theme.space.xl};
    }
    & > div > div:first-child{
      width: auto;
      margin-right: 0;
      svg {
        width: 100%;
      }
    }
    & > div > div:last-child {
        max-width: 295px;
        display: flex;
        align-items: center;
        text-align: center;
        flex-flow: column;
    }
  `}
`

export const Description = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSize.xs};
    text-align: center;
    line-height: 1.38;
    letter-spacing: 0.56px;
  `}
`

export const H2 = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSize.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 1.33;
    letter-spacing: 0.73px;
    margin-bottom: 5px;
  `}
`

export const BtnWrapper = styled.div`
  cursor: pointer;
`
