import MaintenanceBanner from './MaintenanceBanner'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ImageUnsuccessful from './ImageUnsuccessful'
import { SubHeader } from './style'
import router from 'next/router'

interface EmptyLearningPathV2Props {
  title?: React.ReactNode
  description?: React.ReactNode
}

const EmptyLearningPathV3: React.FC<EmptyLearningPathV2Props> = ({ title, description }) => {
  const refreshPage = () => window.location.reload()
  const goToSupport = () => {
    router.push('/support/contact-us')
  }
  return (
    <MaintenanceBanner
      image={<ImageUnsuccessful />}
      header={
        title || (
          <FormattedMessage id="Error loading your learning path" defaultMessage="Error loading your learning path" />
        )
      }
      subHeader={
        <SubHeader>
          <FormattedMessage
            id="Please try reloading the page. If the problem persists please raise a support request."
            defaultMessage="Please try reloading the page. If the problem persists please raise a support request."
          />
          <br />
          {description}
        </SubHeader>
      }
      btnText1={<FormattedMessage id="Reload" defaultMessage="Reload" />}
      btnText2={<FormattedMessage id="Request support" defaultMessage="Request support" />}
      onButtonClick1={refreshPage}
      onButtonClick2={goToSupport}
    />
  )
}

export default EmptyLearningPathV3
