import styled from 'styled-components'
import { H2 as BerlitzH2 } from '@berlitz/globals'
import BerlitzButton from '@berlitzplatforms/micro.ui.button'

export const StyledMaintenanceBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ActionButtons = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    padding-top: ${theme.space.sm};
    padding-bottom: ${theme.space.md};
    button {
        margin-right: ${theme.space.xs};
    }
  `}
`

export const Button = styled(BerlitzButton)`
  ${({ theme }) => `
    padding: ${theme.space.xs} ${theme.space.md};
  `}
`

export const Header = styled(BerlitzH2)`
  ${({ theme }) => `
    font-size: ${theme.fontSize.md};
    font-weight: ${theme.fontWeight.bold};
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    color: ${theme.palette.charcoal};
  `}
`

export const SubHeader = styled.span`
  ${({ theme }) => `
    font-size: ${theme.fontSize.xs};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    color: ${theme.palette.charcoal};
  `}
`

export const Image = styled.div<{ width?: number }>`
  margin-right: ${({ theme }) => theme.space.sm};
  max-width: ${({ width, theme }) => (width ? `${width}px` : theme.layout.xxxl)};
  width: 100%;
`
