import PortalLearningPath from '@classes/LearningPath'
import PortalLearningPathUnit from '@classes/LearningPathUnit'
import LPCard from '@components/LPCard'
import MaterialCard from '@components/LPCard/MaterialCard'
import { StoreProps } from '@interfaces/StoreState'
import FlexLesson from '@layouts/LearningPath/Chapter/Flex/Lesson'
import LiveCoaching from '@layouts/LearningPath/Chapter/Flex/LiveCoaching'
import { Bridge } from '@layouts/LearningPath/Chapter/style'
import { isChromium } from '@utils/browser'
import { LP_STATUS } from '@utils/constants/learningPath'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

export interface FlexChapterProps {
  Unit: PortalLearningPathUnit
  active?: boolean
  bridgeless?: boolean
  dashboard?: boolean
  selectedLanguage?: string
  ActiveUnits?: PortalLearningPathUnit[]
  BookableUnits?: PortalLearningPathUnit[]
  CompletedOrOngoingUnits?: PortalLearningPathUnit[]
  Path?: PortalLearningPath
}

const FlexChapter: React.FC<FlexChapterProps> = ({
  Unit,
  active,
  bridgeless,
  dashboard,
  selectedLanguage,
  ActiveUnits,
  BookableUnits,
  CompletedOrOngoingUnits,
  Path,
}) => {
  const profileType = useSelector(({ userProfile }: StoreProps) => userProfile.info.Type)
  const itemRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    active &&
      itemRef?.current?.parentElement?.scrollIntoView({
        behavior: isChromium() ? 'auto' : 'smooth',
        block: 'center',
        inline: 'center',
      })
  }, [active])
  if (Unit?.IsCulture && selectedLanguage) {
    Unit.Language = selectedLanguage
  }
  return (
    <div ref={itemRef}>
      {Unit?.IsLesson && <FlexLesson Unit={Unit} active={active} Path={Path} />}
      {Unit?.IsReview && (
        <LPCard.Review
          active={active}
          status={Unit.Status}
          url={Unit.Status !== LP_STATUS.COMPLETE ? Unit.EntryPointUrl : Unit.ResetUrl}
          title={Unit.BodyTitleClean}
          unit={Unit}
        />
      )}
      {Unit?.IsCoaching && (
        <LiveCoaching
          Unit={Unit}
          active={active}
          dashboard={dashboard}
          ActiveUnits={ActiveUnits}
          BookableUnits={BookableUnits}
          CompletedOrOngoingUnits={CompletedOrOngoingUnits}
          Path={Path}
        />
      )}
      {Unit?.IsCheckpoint && (
        <LPCard.Checkpoint
          active={active}
          status={Unit.Status}
          url={Unit.EntryPointUrl}
          redo={Unit.ResetUrl}
          title={Unit.Title}
          unit={Unit}
        />
      )}
      {Unit?.IsCulture && (
        <LPCard.Culture active={active} url={Unit.EntryPointUrl} title={Unit.BodyTitleClean} unit={Unit} />
      )}
      {Unit?.IsCourseMetaData && (
        <MaterialCard
          status={Unit.Status}
          active={false}
          attachments={Unit.getAttachments(profileType)}
          title={Unit.Title}
        />
      )}

      {!Unit.IsCourseMetaData && !bridgeless && <Bridge status={Unit.Status} />}
    </div>
  )
}

export default React.memo(FlexChapter)
