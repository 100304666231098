import Button from '@berlitz/button'
import { H1 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { ActionButtons, CenterText } from '../style'
import { StyledH3 } from './style'

interface Props {
  onClose: Function
  onCancel?: Function
  onProceed?: Function
  open: boolean
  isOnDemand?: boolean
}

export const Content: React.FC<Props> = ({ onClose, onCancel, onProceed, open, isOnDemand = false }) => {
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <CenterText>
          <img src="/images/next-level-lg.svg" />
        </CenterText>
        <Spacer size="xxs" />
        <Spacer size="lg" />
        <CenterText>
          <H1 disableMargin>
            <FormattedMessage id="Start next level?" defaultMessage="Start next level?" />
          </H1>
          <Spacer size="xs" />
          <StyledH3>
            <FormattedMessage
              id="Continue your journey with new words and phrases."
              defaultMessage="Continue your journey with new words and phrases."
            />
          </StyledH3>
          <Spacer size="xs" />
          <Spacer size="xxxs" />
          <ActionButtons>
            {!isOnDemand && (
              <Button onClick={onCancel} outline>
                <FormattedMessage id="Redo live coaching sessions" defaultMessage="Redo live coaching sessions" />
              </Button>
            )}
            <Button onClick={onProceed}>
              <FormattedMessage id="Start next level" defaultMessage="Start next level" />
            </Button>
          </ActionButtons>
        </CenterText>
      </Modal>
    </>
  )
}

export default Content
