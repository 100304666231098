import SpeakingPractice from '@components/LPCard/SpeakingPractice'
import UnlockedMultiple from '@components/LPCard/SPPanel/UnlockedMultiple'
import { FlexChapterProps } from '@layouts/LearningPath/Chapter/Flex/index'
import { FLEX_LEVEL_STATUS } from '@utils/constants'
import { LP_STATUS } from '@utils/constants/learningPath'
import React from 'react'

const LiveCoaching: React.FC<FlexChapterProps> = ({ Unit, active, dashboard, BookableUnits, Path }) => {
  if (dashboard) {
    // takes priority over other checkings
    if (Unit.HasContractEnded && !Unit.IsBooked) {
      return <SpeakingPractice.Completed Unit={Unit} InDashboard={true} />
    }

    if (
      [FLEX_LEVEL_STATUS.ENROLMENT_COMPLETED, FLEX_LEVEL_STATUS.ENROLMENT_INPROGRESS].indexOf(
        Path?.Material?.FlexLevelStatus || ''
      ) >= 0
    ) {
      return Unit.IsBooked ? (
        <SpeakingPractice.DashJoinOption Unit={Unit} />
      ) : (
        <SpeakingPractice.DashLevelUp Unit={Unit} />
      )
    } else if (
      [
        FLEX_LEVEL_STATUS.UNLIMITED_LCS,
        FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL,
        FLEX_LEVEL_STATUS.ALL_LEVELS_COMPLETED,
      ].indexOf(Path?.Material?.FlexLevelStatus || '') >= 0
    ) {
      return Unit.IsBooked ? (
        <SpeakingPractice.DashJoinOption Unit={Unit} />
      ) : (
        <SpeakingPractice.DashUnlimited Unit={Unit} Path={Path} />
      )
    } else {
      // FLEX_LEVEL_STATUS = LEVEL_STARTED or null? for regular flex programs?
      switch (Unit.Status) {
        case LP_STATUS.LOCKED:
          return <SpeakingPractice.DashLocked Unit={Unit} />

        case LP_STATUS.COMPLETE:
          return Unit.UnlimitedLCS && !Unit.IsBooked && !Path?.IsPreviousLP ? (
            <SpeakingPractice.DashUnlimited Unit={Unit} Path={Path} />
          ) : Path?.IsPreviousLP && !Unit.IsBooked ? (
            <SpeakingPractice.DashLevelUp Unit={Unit} />
          ) : Unit.IsBooked ? (
            <SpeakingPractice.DashJoinOption Unit={Unit} />
          ) : (
            <SpeakingPractice.DashCompleted Unit={Unit} />
          )

        case LP_STATUS.UNLOCKED:
          return Unit?.IsBooked || (Unit.Session && !Unit.UnlimitedLCS) ? (
            <SpeakingPractice.DashJoinOption Unit={Unit} />
          ) : (BookableUnits || []).length > 1 ? (
            <UnlockedMultiple ActiveUnits={BookableUnits} CardType="speakPrac" Path={Path} />
          ) : (
            <SpeakingPractice.DashUnlocked Unit={Unit} Path={Path} />
          )

        default:
          return <></>
      }
    }
  }

  if (Unit.Status === LP_STATUS.LOCKED && !Unit.UnlimitedLCS) {
    return <SpeakingPractice.Locked active={active} Unit={Unit} />
  }

  if (Unit.Status === LP_STATUS.COMPLETE && (!Unit.UnlimitedLCS || (Unit.UnlimitedLCS && !Unit.IsUnitBooked))) {
    return <SpeakingPractice.Completed active={active} Unit={Unit} />
  }

  if (
    Unit.IsLCSZero &&
    Unit.Session?.lesson?.UnitId === String(Unit.Id) &&
    (Unit?.IsBooked || (Unit.Session && !Unit.UnlimitedLCS))
  ) {
    return <SpeakingPractice.DashJoinOption Unit={Unit} />
  }

  return Unit.IsLCSZero ? (
    <SpeakingPractice.DashUnlocked Unit={Unit} Path={Path} />
  ) : Unit.Session?.lesson?.UnitId === String(Unit.Id) && (Unit?.IsBooked || (Unit.Session && !Unit.UnlimitedLCS)) ? (
    <SpeakingPractice.DashJoinOption Unit={Unit} />
  ) : Unit?.UnlimitedLCS && !Path?.HasCompletedAllLCS && !Unit.IsCoachingActive ? (
    <SpeakingPractice.Locked active={active} Unit={Unit} />
  ) : (
    <SpeakingPractice.Unlocked active={active} Unit={Unit} Path={Path} />
  )
}

export default LiveCoaching
