import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import Modal, { ElectiveOption } from '@berlitzplatforms/micro.modal.select-elective/lib'
import PortalLearningPath from '@classes/LearningPath'
import useEnsureNoDisplayedModal from '@hooks/useEnsureNoDisplayedModal'
import { ElectivesQueryResult } from '@interfaces/Electives'
import { GET_ELECTIVES } from '@queries/electives'
import { PUT_REGISTRATION_MATERIALS } from '@queries/registrations'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingModal from '../LoadingModal'
import WarningModal from '../WarningModal'

export interface SelectElectiveModalProps {
  MainMaterialId: string
  StudentProfileId: string
  Path: PortalLearningPath
  autoOpen?: boolean
  open?: boolean
  onClose?: () => void
}

export const SelectElectiveModal: React.FC<SelectElectiveModalProps> = ({
  MainMaterialId,
  StudentProfileId,
  Path,
  autoOpen,
  open: manualOpen,
  onClose = () => {},
}) => {
  const intl = useIntl()
  const [open, setOpen] = useState(!!manualOpen)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<ElectiveOption[]>()
  const [defaultVal, setDefaultVal] = useState<string>()
  const [error, setError] = useState(false)
  const [putElective, { loading: loadingPut, error: putError }] = useMutation(PUT_REGISTRATION_MATERIALS, {
    onError: () => {},
    onCompleted: async () => {
      location.reload()
    },
  })

  const [getElectives, { data: electives, loading: loadingE, error: eError }] = useLazyQuery<ElectivesQueryResult>(
    GET_ELECTIVES,
    {
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    getElectives({
      variables: {
        MainMaterialId,
        StudentProfileId,
      },
    })
  }, [])

  useEffect(() => {
    setError(!!putError || !!eError)
  }, [putError, eError])

  useEnsureNoDisplayedModal(
    () => {
      if (electives?.getElectives?.mainAndElectiveMaterialInfo?.electiveMaterials?.length) {
        const opts = electives?.getElectives?.mainAndElectiveMaterialInfo?.electiveMaterials?.map((opt) => ({
          value: opt.electiveMaterialId,
          label: opt.electiveMaterialName,
          key: opt.electiveMaterialId,
        }))
        setDefaultVal(opts[0].value)
        setOptions(opts as ElectiveOption[])
      }
    },
    [electives],
    autoOpen && !!localStorage.getItem(Path.tempFlags.SelectElective)
  )

  useEffect(() => {
    if (options?.length) {
      const tempFlag = localStorage.getItem(Path.tempFlags.SelectElective)
      const shouldOpen = !!autoOpen && !tempFlag
      setOpen(shouldOpen)

      if (shouldOpen) {
        localStorage.setItem(Path.tempFlags.SelectElective, 'true')
      }
    }
  }, [JSON.stringify(options)])

  useEffect(() => {
    typeof manualOpen === 'boolean' && setOpen(manualOpen && !!options?.length)
  }, [manualOpen, JSON.stringify(options)])

  useEffect(() => {
    setLoading(loadingE && !!manualOpen && !open)
  }, [loadingE, manualOpen, open])

  const MemoModal = useMemo(
    () =>
      defaultVal ? (
        <Modal
          open={open}
          onClose={() => {
            setOpen(false)
            onClose()
          }}
          title={
            <FormattedMessage
              id="Welcome to {name}!"
              defaultMessage="Welcome to {name}!"
              values={{ name: Path?.Material?.MaterialName }}
            />
          }
          description={
            <FormattedMessage
              id="Before you start your level please select the elective course you'd like to take."
              defaultMessage="Before you start your level please select the elective course you'd like to take."
            />
          }
          buttonText={<FormattedMessage id="Begin your course" defaultMessage="Begin your course" />}
          options={options}
          defaultValue={defaultVal}
          onButtonClick={(selected) => {
            setOpen(false)
            onClose()
            putElective({
              variables: {
                registrationMaterialInput: [
                  {
                    RegistrationMaterial: {
                      RegistrationId: Path.Material?.RegistrationId,
                      MaterialId: selected,
                    },
                  },
                ],
              },
            })
          }}
          selectLabel={intl.formatMessage({ id: 'Select an elective' })}
        />
      ) : null,
    [JSON.stringify(options), Path?.Id, defaultVal, open]
  )

  return (
    <>
      {MemoModal}
      <LoadingModal
        open={loading || loadingPut}
        onClose={() => {}}
        title={`${intl.formatMessage({
          id: loadingPut || error ? 'Updating course...' : 'Fetching electives...',
        })}`}
        subtitle={`${intl.formatMessage({ id: 'Please wait a moment' })}.`}
      />
      <WarningModal open={error} onClose={() => setError(false)} anApiError noButtons />
    </>
  )
}
