import gql from 'graphql-tag'

export const GET_TEST_RESULTS = gql`
  query GetTestResult($username: String!, $lpid: String!, $attempt_number: String!) {
    getTestResult(username: $username, lpid: $lpid, attempt_number: $attempt_number) {
      student_id
      learning_language
      learning_level
      learning_path_name
      lpid
      unit_id
      cmid
      round_id
      unit_status
      number_of_attempts
      quizattempts {
        attempt_number
        attempt_id
        attempt_start_date
        attempt_end_date
        test_result
        total_correct_answers
        total_questions
        questions {
          question_id
          question
          given_answer
          is_correct_answer
          expected_answer
        }
      }
    }
  }
`

export const RESET_TEST = gql`
  query ResetFlex($cmid: Float!, $username: String!) {
    resetFlex(cmid: $cmid, username: $username) {
      data {
        cmid
      }
    }
  }
`
