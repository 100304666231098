import { useMutation } from '@apollo/react-hooks'
import AllYouCanLearnCompletionModal from '@berlitzplatforms/micro.modal.all-you-can-learn-completion'
import PortalLearningPath from '@classes/LearningPath'
import FormattedHTMLMessage from '@components/FormattedHTMLMessage'
import useEnsureNoDisplayedModal from '@hooks/useEnsureNoDisplayedModal'
import { PUT_REGISTRATION_FLEX_LEVEL_STATUS } from '@queries/registrations'
import { FLEX_LEVEL_STATUS } from '@utils/constants'
import { isEmpty } from 'lodash'
import Router from 'next/router'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SubHeader } from './style'
import PortalStudentProfile from '@classes/StudentProfile'
import { RegistrationMaterial, RegistrationWrapper } from '@interfaces/Student'
import useCheckCTShowModal from './useCheckCTShowModal'
import Spacer from '@berlitz/spacer'

interface AllLevelsCompletionModalProps {
  learningPath: PortalLearningPath
  studentProfile?: PortalStudentProfile
  registration?: RegistrationWrapper
}

export const ModalMessage = ({ name, isOnDemand = false, isFlex = false, isCurriculumTest = false }) => (
  <>
    <SubHeader>
      <FormattedHTMLMessage
        id="You've completed {lp}!"
        defaultMessage="You've completed {lp}!"
        values={{ lp: `<span style="color: #004fef">${name}</span>` }}
      />
    </SubHeader>
    <div>
      {isCurriculumTest && (
        <FormattedMessage
          id="You're now ready to take the curriculum test! You can also view your Certificate of Achievement by clicking the button below."
          defaultMessage="You're now ready to take the curriculum test! You can also view your Certificate of Achievement by clicking the button below."
        />
      )}

      <Spacer />

      {isOnDemand && (
        <FormattedMessage
          id="Keep practicing by redoing learning activities until your course end date, or explore more Berlitz courses."
          defaultMessage="Keep practicing by redoing learning activities until your course end date, or explore more Berlitz courses."
        />
      )}

      {isFlex && (
        <FormattedMessage
          id="Practice by redoing live coaching sessions until your course end date, or contact us about other courses."
          defaultMessage="Practice by redoing live coaching sessions until your course end date, or contact us about other courses."
        />
      )}
    </div>
  </>
)

export interface IModalAllYouCanLearnCompletionBody {
  MaterialName?: string
  ProgramId?: string
  LPId?: string
  RegistrationId?: string
  CurriculumTestId?: string
  AllLevelsCompletedKey?: string
  AllLevelsCompletedModalKey?: string
  CurriculumTestModalKey?: string
  defaultOpenModal?: boolean
  isFlex?: boolean
  isOnDemand?: boolean
  StudentProfile?: PortalStudentProfile
  Registration?: RegistrationWrapper
  Material?: RegistrationMaterial
}

export const ModalAllYouCanLearnCompletion: React.FC<IModalAllYouCanLearnCompletionBody> = ({
  MaterialName,
  ProgramId,
  LPId,
  RegistrationId,
  CurriculumTestId,
  AllLevelsCompletedKey = '',
  AllLevelsCompletedModalKey = '',
  CurriculumTestModalKey = '',
  defaultOpenModal,
  isFlex,
  isOnDemand,
  StudentProfile,
  Registration,
  Material,
}) => {
  const intl = useIntl()
  const [openCompletionModal, setOpenCompletionModal] = React.useState(false)
  const [updateFlexLevelStaus] = useMutation(PUT_REGISTRATION_FLEX_LEVEL_STATUS)
  const openModal = useCheckCTShowModal(
    Boolean(defaultOpenModal),
    AllLevelsCompletedKey,
    Material,
    StudentProfile,
    Registration
  )

  useEnsureNoDisplayedModal(() => {
    if (openModal) {
      setOpenCompletionModal(localStorage.getItem(AllLevelsCompletedModalKey) !== 'true')
    }
  }, [openModal])

  const closeModal = () => {
    // temp flag so we won't show the modal anymore
    localStorage.setItem(AllLevelsCompletedKey, 'true')
    localStorage.setItem(AllLevelsCompletedModalKey, 'true')
    localStorage.setItem(CurriculumTestModalKey, 'true')

    setOpenCompletionModal(false)
  }

  const goToScheduling = () => {
    closeModal()
    Router.push(`/scheduling/single-lesson?programid=${ProgramId}&unitId=0000&LPId=${LPId}`)
  }

  const goToLearningPath = () => {
    closeModal()
    Router.push(`/learning-path/?dd=${CurriculumTestId}`)
  }

  const goToLearningPathWithSelectedDD = () => {
    // set value here to pre select ciriculum test for program selected
    goToLearningPath()
  }

  const viewAchievementCert = () => {
    closeModal()
    Router.push('/settings/certificates')
  }

  //const isCurriculumTest = !isFlex && !isOnDemand
  // If registration contains test material
  // and registration status is test unlock
  // isCurriculumTest = true
  // else isCurriculumTest = false
  const isCurriculumTest = true

  const btn1Label = isCurriculumTest
    ? 'View Certificate of Achievement'
    : isOnDemand
      ? 'Redo learning activities'
      : 'Redo live coaching sessions'

  const btn2Label = isCurriculumTest ? 'Go to curriculum test' : 'View other courses'

  return (
    <AllYouCanLearnCompletionModal
      open={openCompletionModal}
      onClose={closeModal}
      onButton1Click={isCurriculumTest ? viewAchievementCert : isOnDemand ? goToLearningPath : goToScheduling}
      onButton2Click={() => {
        if (isCurriculumTest) {
          goToLearningPathWithSelectedDD()
        } else {
          const url = intl.formatMessage({
            id: 'view-other-courses-link',
            defaultMessage: 'https://www.berlitz.com',
          })
          window.open(url, '_blank')
          closeModal()
        }
      }}
      description={
        <ModalMessage name={MaterialName} isOnDemand={isOnDemand} isFlex={isFlex} isCurriculumTest={isCurriculumTest} />
      }
      title={intl.formatMessage({ id: 'Congratulations!', defaultMessage: 'Congratulations!' })}
      button1Label={intl.formatMessage({
        id: btn1Label,
        defaultMessage: btn1Label,
      })}
      button2Label={intl.formatMessage({
        id: btn2Label,
        defaultMessage: btn2Label,
      })}
    />
  )
}

const AllLevelsCompletionModal: React.FC<AllLevelsCompletionModalProps> = ({
  learningPath,
  studentProfile,
  registration,
}) => {
  return (
    <ModalAllYouCanLearnCompletion
      MaterialName={learningPath.AYCLSequence}
      CurriculumTestId={learningPath?.CurriculumTests?.[0]?.Id}
      ProgramId={learningPath?.Program?.ProgramId}
      LPId={learningPath?.Id}
      RegistrationId={learningPath.Material?.RegistrationId}
      AllLevelsCompletedKey={learningPath.tempFlags.AllLevelsCompleted}
      AllLevelsCompletedModalKey={learningPath.tempFlags.AllLevelsCompletedModal}
      defaultOpenModal={learningPath && learningPath.IsRecentlyCompletedAllYouCanLearn}
      isFlex={!isEmpty(learningPath.IsFlex)}
      isOnDemand={learningPath.IsOnDemand}
      StudentProfile={studentProfile}
      Registration={registration}
      Material={learningPath.Material}
    />
  )
}

export default React.memo(AllLevelsCompletionModal)
