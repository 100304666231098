import gql from 'graphql-tag'

export const GET_ELECTIVES = gql`
  query GetElectives($MainMaterialId: String!, $StudentProfileId: String!) {
    getElectives(MainMaterialId: $MainMaterialId, StudentProfileId: $StudentProfileId) {
      mainAndElectiveMaterialInfo {
        mainMaterialId
        electiveMaterials {
          Id
          electiveMaterialId
          electiveMaterialName
        }
      }
    }
  }
`
