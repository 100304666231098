import Button from '@berlitz/button'
import { ArrowDown } from '@berlitz/icons'
import AttachmentIcon from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/27-Link-Unlink/Attachment'
import PortalLearningPathAttachment from '@classes/LearningPathAttachments'
import PortalLearningPathUnit from '@classes/LearningPathUnit'
import {
  Container,
  Content,
  DownloadContainer,
  DownloadSelection,
  Link,
  Text,
  TileContainer,
} from '@layouts/LearningPath/Chapter/style'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  status?: string
  active?: boolean
  title?: string
  attachments?: PortalLearningPathAttachment[]
  Unit?: PortalLearningPathUnit
}

const MaterialCard: React.FC<Props> = ({ active, status, title, attachments, Unit }) => {
  const [downloadSelection, setDownloadSelection] = React.useState(false)
  return (
    <Container>
      <TileContainer active={active} status={status}>
        <Content>
          <AttachmentIcon color="brand03" size="sm" />
          <Text status={status}>{title}</Text>
          <Link status={status} is3rdParty={Unit?.Is3rdPartyMaterial && !Unit?.Is3rdPartyMaterialHasAttachments}>
            {Unit?.Is3rdPartyMaterial && !Unit?.Is3rdPartyMaterialHasAttachments ? (
              <FormattedMessage id="Please refer to your printed material or publisher's website" />
            ) : (
              <FormattedMessage id="For the most up-to-date content, it's best to view your learning materials online and download them when you reach each lesson. You can download the learning material for all lessons here, but, please note, doing so means you may miss out on any new content or updates." />
            )}
          </Link>
          {(!Unit?.Is3rdPartyMaterial || (Unit?.Is3rdPartyMaterial && Unit?.Is3rdPartyMaterialHasAttachments)) && (
            <DownloadContainer status={status}>
              <Button onClick={() => setDownloadSelection(!downloadSelection)} fullWidth outline>
                <FormattedMessage id="Download" />
                <ArrowDown color="brand01" size="sm" />{' '}
              </Button>
              {downloadSelection && (
                <DownloadSelection status={status}>
                  <ul>
                    {attachments?.map((Attachment, index) => (
                      <li key={index}>
                        <div onClick={() => window.open(Attachment.Url, '_blank')}>
                          <span>{Attachment.Name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </DownloadSelection>
              )}
            </DownloadContainer>
          )}
        </Content>
        <div style={{ height: '38px' }} />
      </TileContainer>
    </Container>
  )
}

export default MaterialCard
