import PortalStudentProfile from '@classes/StudentProfile'
import Select from '@components/Select'
import useCTPermission from '@hooks/useCTPermission'
import { StoreProps } from '@interfaces/StoreState'
import { ProgramSelectorProp } from '@layouts/Dashboard/HeroBlock/interface'
import { ProgramsWrapper } from '@layouts/Dashboard/HeroBlock/style'
import { updateDashboardActiveMaterial } from '@redux/actions/dashboard'
import { CURRICULUM_TEST_STATUS, REG_STATUS } from '@utils/constants'
import { getQueryParams } from '@utils/helpers'
import { getStoredMaterials } from '@utils/helpers/material'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

const RegStatusInOrder = [
  REG_STATUS.PENDING_START,
  REG_STATUS.ACTIVE,
  REG_STATUS.COMPLETED,
  REG_STATUS.EXPIRED,
  REG_STATUS.PENDING_START,
  REG_STATUS.CANCELLED,
  REG_STATUS.DRAFT,
  REG_STATUS.IN_PROGRESS,
  REG_STATUS.PAUSED,
  REG_STATUS.EXPIRED,
]

const ProgramSelector: React.FC<ProgramSelectorProp> = ({
  hide,
  defaultProgram,
  bgOn,
  StudentProfile,
  label = '',
  showCT,
  LearningPaths,
  setShowSelector = () => {},
}) => {
  const curriculumTestId = getQueryParams('dd')
  const router = useRouter()
  const { activeMaterial, Profile } = useSelector(({ userProfile, dashboard, permissions }: StoreProps) => ({
    Profile: StudentProfile || new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo),
    activeMaterial: dashboard.activeMaterial,
    userPermissions: permissions.permissions,
  }))

  const { getPermissionByProgramType } = useCTPermission()

  let materialsDD = showCT ? Profile.LPMaterialDropDown : Profile.MaterialDropDown
  materialsDD = materialsDD
    .map((material) => {
      return {
        ...material,
        sortValue: RegStatusInOrder.indexOf(material.regStatus ?? ''),
        suboptions: material.suboptions.filter(({ material: mat }) => {
          if (mat?.IsTest) {
            const ctFlag = getPermissionByProgramType(mat?.ProgramType)
            const isCTAllowed =
              (mat?.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED || CURRICULUM_TEST_STATUS.COMPLETED) && ctFlag
            return isCTAllowed
          }
          return true
        }),
      }
    })
    .filter((material) => material.material)

  const dispatch = useDispatch()
  const stored = getStoredMaterials()?.[Profile.SFId]
  const opt = localStorage.getItem('activeOPT')
  const determinSelectedMaterial = () => {
    const defaultMaterial = showCT
      ? opt || curriculumTestId || defaultProgram || activeMaterial || materialsDD?.[0]?.value
      : opt ||
        curriculumTestId ||
        defaultProgram ||
        materialsDD.find((i) => i.value === activeMaterial)?.value ||
        materialsDD?.[0]?.value

    const material = LearningPaths?.getLearningPathById(defaultMaterial)
    if (material?.IsCTMaterial) {
      const permission = getPermissionByProgramType(material?.Material?.ProgramType)
      if (permission) {
        return defaultMaterial
      }

      return material.MaterialCore?.LPID
    }
    return defaultMaterial
  }
  const [selectedMaterial] = useState(determinSelectedMaterial())
  const [value, setValue] = useState(materialsDD?.[0]?.value)
  useEffect(() => {
    if (!activeMaterial && materialsDD.length) {
      dispatch(updateDashboardActiveMaterial(selectedMaterial))
    }
  }, [])

  useEffect(() => {
    if (curriculumTestId) {
      setValue(curriculumTestId)
      dispatch(updateDashboardActiveMaterial(curriculumTestId))
      router.push(`/learning-path`)
    }
  }, [curriculumTestId])

  useEffect(() => {
    // handle programId or materialId being passed
    if (materialsDD.length) {
      let match = materialsDD.find(
        (item) =>
          item.value === selectedMaterial || item.suboptions?.find((subopt) => subopt.value === selectedMaterial)
      )
      if (match && match?.value !== selectedMaterial) {
        match = match?.suboptions?.find((subopt) => subopt.value === selectedMaterial)
      }
      const programMatch = materialsDD.find((item) => item.data?.ProgramId === selectedMaterial)
      setValue(match?.value || programMatch?.value || materialsDD?.[0]?.value)
    }
  }, [selectedMaterial])

  // this is for module functionality
  // we need to hide the container of the program selector when there is no program selector to fix layout issues
  useEffect(() => {
    if (hide || (materialsDD.length < 2 && !materialsDD.find((dd) => dd.suboptions?.length > 1))) {
      setShowSelector(false)
    }
  }, [materialsDD])

  /**
   * A use effect that will remove value from activeOPT
   * once it is displayed in the browser
   */
  useEffect(() => {
    if (value === localStorage.getItem('activeOPT')) {
      localStorage.removeItem('activeOPT')
    }
  }, [value])

  if (hide || (materialsDD.length < 2 && !materialsDD.find((dd) => dd.suboptions?.length > 1))) {
    return null
  }

  return (
    <ProgramsWrapper bgOn={bgOn}>
      {!label && (
        <span>
          <FormattedMessage id="Select your course:" />
        </span>
      )}
      <div>
        <Select
          label={label}
          name="Program"
          value={value}
          onChange={(LPID) => {
            const materials = getStoredMaterials()
            materials[Profile.SFId] = LPID
            localStorage.setItem(`portal-selected-material`, JSON.stringify(materials))
            setValue(LPID)
            dispatch(updateDashboardActiveMaterial(LPID))
          }}
          options={materialsDD}
          disableMargin
          responsive
        />
      </div>
    </ProgramsWrapper>
  )
}

export default ProgramSelector
